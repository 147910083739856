.publishing-preview-container {
  position: relative;
  width: 100%;
  max-width: 375px;

  .publish-preview-image {
    width: 100%;
    object-fit: contain;
    border-radius: 12px;
    overflow: hidden;
  }

  .app-icon-preview {
    position: absolute;
    top: 10.5%;
    left: 11%;
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .studio-icon {
      border-radius: 21px;
      width: 100%;
    }

    .app-name {
      font-size: 12px;
      color: #fff;
      margin-top: 2px;
      text-align: center;
    }
  }
}
