.customer-overview-wrapper {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-bottom: 40px;
}

.user-list-wrapper {
  width: 100%;
}
