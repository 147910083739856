@import "styles/themes.scss";

.running-text {
  font-size: $font-size-running-text;
  line-height: 125%;

  @include themed() {
    color: t("color-text");
  }
}
