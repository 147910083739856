@import "styles/themes.scss";

.progress-bar-container {
  height: 6px;
  background-color: #d6d9d8;
  border-radius: 3px;

  .progress-bar {
    height: 100%;
    border-radius: 3px;
  }

  &--color-primary {
    .progress-bar {
      @include themed() {
        background-color: t("color-primary");
      }
    }
  }

  &--color-secondary {
    .progress-bar {
      @include themed() {
        background-color: t("color-secondary");
      }
    }
  }
}
