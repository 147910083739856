@import "styles/themes.scss";

.fact-card-wrapper {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  max-width: calc(50% - 20px);
  cursor: pointer;

  @include themed() {
    background-color: t("color-secondary");
  }

  &:hover {
    @include themed() {
      background-color: t("color-primary");
    }
  }
}
