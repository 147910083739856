@import "styles/themes.scss";
@import "styles/utils.scss";

.nav-list-item-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px $padding-page-content-left-right;
  box-sizing: border-box;
  box-sizing: border-box;
  gap: 16px;
  align-items: center;

  @include for-phone-only {
    padding: 10px $padding-page-content-left-right-mobile;
  }

  @include themed() {
    &:not(&--active):hover {
      background-color: t("color-hover");
    }
  }

  &--active {
    @include themed() {
      background-color: t("color-primary");
    }
  }

  .nav-list-item {
    &-text-container {
      flex: 2;
      padding-left: 10px;
      text-overflow: ellipsis;

      .nav-list-sub-title {
        margin-top: 2px;
      }
    }
  }
}
