@import "styles/themes.scss";

.device-preview-container {
  width: 100%;
  max-width: 460px;
  min-height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  @include themed() {
    border: 2px solid t("color-primary");
  }

  .choose-device {
    @include themed() {
      color: t("color-primary");
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.device-setup-device {
  padding: 15px;
}
