@import "styles/themes.scss";
.device-detail-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .device-detail-header {
    padding: 0 0 25px 0;

    .device-detial-identifier-container {
      height: 100%;
      align-items: center;
      display: flex;

      .device-detial-identifier {
        @include themed() {
          color: t("color-primary");
        }
      }
    }

    .device-detial-image-container {
      .device-detail-image {
        width: 100px;
        height: 100px;
      }
    }

    .device-detail-info-container {
      .device-detail-title-container {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }
  }

  .device-detail-body {
    width: 100%;
    height: 100%;
    margin-top: 25px;
  }
}
