@import "styles/themes.scss";
@import "styles/utils.scss";

.page-headline-container {
  padding: 0px $padding-page-content-left-right;
  min-height: 72px;

  @include themed() {
    background-color: t("color-background");
    border-bottom: 1.5px solid t("color-border");
  }

  @include for-phone-only {
    overflow-y: scroll;
  }

  .page-headline-left-actions {
    width: auto;
  }

  &-actions {
    width: auto;
  }
}
