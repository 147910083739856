@import "styles/themes.scss";

.training-plan-exercise-item {
  padding: 20px 40px;
  cursor: pointer;

  &-header {
    cursor: pointer;
  }

  &-opened {
    padding: 20px 40px;

    @include themed() {
      background-color: t("color-surface-dark");
    }
  }
}

.training-plan-info-input {
  max-width: calc(50% - 10px);
}
