@import "styles/themes.scss";

.selection-modal-wrapper {
  height: 100%;
  max-height: 80vh;
  min-height: 80vh;
  display: flex;
  flex-direction: column;

  .selection-modal-body-container {
    flex-grow: 1;
    overflow: auto;
  }

  .exercises-list-wrapper {
    overflow-y: auto;

    @include themed() {
      background-color: t("color-surface");
    }
  }

  .bottom-action-bar {
    flex-shrink: 0;
    padding: 20px;

    @include themed() {
      border-top: 1.5px solid t("color-border");
    }
  }
}
