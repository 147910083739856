$themes: (
  light: (
    // Color themes
    color-primary: rgb(48, 213, 152),
    color-primary-highlight: #2bbf8c,
    color-primary-ultralight: #e6f9f4,
    color-secondary: #000000,
    color-secondary-highlight: #5c5c5c,
    color-background: #ffffff,
    color-inverted: #000000,
    color-surface: rgb(248, 250, 250),
    color-surface-dark: #f2f2f2,
    color-border: #dddddd,
    color-error: #fd5d5d,
    color-success: rgb(92, 195, 122),
    color-hover: hsla(158, 66%, 51%, 0.1),
    color-warning: #f8b400,
    color-disabled: #cacecd,

    // Text themes
    color-text: #313634,
    color-text-secondary: #000000,

    // Shadow themes
    shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1),
    // Component themes
    color-navigation-bar: transparent
      linear-gradient(270deg, #222222 0%, #080808 100%) 0% 0% no-repeat
      padding-box,
  ),
  dark: (
    // Color themes
    color-primary: rgb(48, 213, 152),
    color-primary-highlight: #2bbf8c,
    color-primary-ultralight: #e6f9f4,
    color-secondary: #000000,
    color-secondary-highlight: #5c5c5c,
    color-surface-dark: #f2f2f2,
    color-background: #000000,
    color-inverted: #ffffff,
    color-surface: #212322,
    color-border: #707070,
    color-error: #fd5d5d,
    color-success: #5cc37a,
    color-warning: #f8b400,
    color-hover: #292c2d,
    color-disabled: #cacecd,

    // Text themes
    color-text: #ebe5e5,

    // Shadow themes
    shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3),
    // Component themes
    color-navigation-bar: transparent
      linear-gradient(270deg, #222222 0%, #080808 100%) 0% 0% no-repeat
      padding-box,
  ),
);

// Font sizes
$font-size-large-headline: 34px;
$font-size-default-headline: 24px;
$font-size-small-headline: 18px;

$font-size-large-text: 16px;
$font-size-title-text: 15px;
$font-size-running-text: 13px;
$font-size-small-text: 12px;

// Heights
$mobile-nav-height: 60px;

// border radius
$main-border-radius: 10px;

// Font weights
// (when changing, also change the @font-face in src/index.scss)
$font-weight-bold: 600;
$font-weight-semi-bold: 500;
$font-weight-medium: 400;
$font-weight-regular: 300;
$font-weight-light: 200;

// Shadows
// ...

// Paddings
$modal-page-padding: 45px;
$default-page-padding: 20px;
$padding-page-content-left-right: 30px;
$padding-page-content-left-right-mobile: 20px;
$padding-page-content: 30px $padding-page-content-left-right 30px
  $padding-page-content-left-right;

// Margins
// ...

// Widths
$max-xxl: 1250px;
$max-xl: 750px;
$max-xml: 500px;
$max-l: 400px;
$max-m: 300px;
$max-s: 200px;

// Utils for theme mode switcher
@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themedbody() {
  @each $theme, $map in $themes {
    .theme--#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
