.x-mark-icon {
  margin-left: 13px !important;
  margin-right: 10px !important;
}

.trash-icon {
  margin-left: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: auto;
}

.exercise-index-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
