@import "styles/themes.scss";

.payment-method-option {
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 10px;
    cursor: pointer;
  
    @include themed() {
      border: 1.5px solid t("color-border");
    }
  
    &--active {
      @include themed() {
        border: 1.5px solid t("color-primary");
      }
    }
  }