@import "styles/themes.scss";

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themed() {
    background-color: t("color-secondary");
  }
}
