@import "styles/themes.scss";

.component-container {
  &--auto-width {
    width: auto;
  }

  .component-wrapper-container {
    display: flex;
    flex-direction: column;
    border-radius: $main-border-radius;
    overflow: hidden;
    position: relative;

    @include themed() {
      border: 1.5px solid t("color-border");
      //  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      background-color: t("color-background");
      // box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      //   rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 5px;
      padding-left: 5px;
    }

    &-content {
      padding: 25px;
      overflow-y: auto;
      box-sizing: border-box;

      @include themed() {
        // background-color: t("color-background");
      }

      &--disabled {
        cursor: pointer;
        pointer-events: none !important;
        opacity: 0.7 !important;
      }
    }

    &--no-padding {
      .component-wrapper-container-content {
        padding: 0px;
      }
    }

    &--info-item {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;

      &:hover {
        @include themed() {
          color: t("color-primary");
        }
      }
    }
  }
}
