.color-input-container .default-input-field {
  height: 50px;
  width: 50px;
  padding: 4px;
}

.color-input-circle {
  height: 26px;
  width: 26px;
  border-radius: 22%;
}
