@import "styles/themes.scss";

.user-setup-preview-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themed() {
    background-color: t("color-primary");
  }

  .user-setup-preview-image {
    width: 100%;
    max-width: 500px;
  }
}
