@import "styles/themes.scss";

.list-placeholder-container {
  padding: 40px;
  margin: 20px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  border-radius: 15px;

  @include themed() {
    background-color: t("color-primary");
  }

  &-text {
    color: white !important;
    text-align: center;
  }

  .list-placeholder-container-image {
    margin-bottom: 20px;
    height: 140px;
  }
}
