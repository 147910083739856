@import "styles/themes.scss";

.studio-setup-app-preview-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themed() {
    background-color: t("color-primary");
  }
}
