@import "styles/themes.scss";

.exercise-detail-wrapper {
  display: flex;
  flex-direction: column;

  .exercise-detail-header {
    padding: 0 0 25px 0;
  }
}
