@import "styles/themes.scss";

.color-picker-container {
  position: relative;
}

.color-preview {
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  border-radius: $main-border-radius;
}

.color-picker-label {
  font-size: $font-size-small-text;
  cursor: pointer;
  margin: 4px;

  @include themed() {
    color: #919191;
  }
}

.popover {
  position: absolute;
  top: -100px;
  z-index: 100;
}
