@import "styles/themes.scss";

.plan-list-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  @include themed() {
    border-right: 1.5px solid t("color-border");
    background: t("color-surface");
  }
}
