@import "styles/themes.scss";

.marketplace-item-wrapper {
  border-radius: $main-border-radius;

  @include themed() {
    box-shadow: t("shadow");
  }

  .image-wrapper {
    max-width: 230px;
  }

  .marketplace-item-image {
    width: 100%;
    margin: 20px;
  }

  .marketplace-content-wrapper {
    padding: 15px;
  }
}
