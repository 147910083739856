@import "styles/themes.scss";

.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .timeline {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;

    .timeline-line {
      position: absolute;
      top: calc(25% - 1px);
      left: -50%;
      right: 50%;
      height: 2px;
      background-color: #ccc;

      &.active {
        @include themed() {
          background-color: t("color-primary");
        }
      }
    }

    .timeline-event {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      width: calc(100% / 4);
      font-size: 16px;

      &.active .timeline-dot {
        @include themed() {
          border-color: t("color-primary");
          background-color: t("color-primary");
        }
      }

      .timeline-dot {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto 10px;
        border: 2px solid #ccc;
        border-radius: 50%;
        background-color: #fff;
        z-index: 1;
      }

      .timeline-label {
        font-size: 14px;
        color: #666;
      }

      &:not(:first-child)::before {
        content: "";
        position: absolute;
        top: calc(50% + 10px);
        left: calc(-50% + 10px);
        height: 2px;
        background-color: #ccc;
        width: calc(100% / 4 - 20px);
        z-index: -1;
      }
    }
  }
}
