@import "styles/themes.scss";

.tag-filter-bar {
  width: 100%;

  .tag-btn {
    margin: 5px 8px 5px 0;
    border-radius: $main-border-radius;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 6px 15px;
    box-sizing: border-box;

    @include themed() {
      background-color: t("color-background");
      color: t("color-text");
      border: 1px solid t("color-border");
    }

    &:not(&--selected):hover {
      @include themed() {
        background-color: t("color-hover");
      }
    }

    &--selected {
      border: 1px solid transparent;

      @include themed() {
        background-color: t("color-inverted");
        color: t("color-background");
      }
    }
  }
}
