@import "styles/themes.scss";

.calendar-page-wrapper {
  height: calc(100% - 15px);

  &--loading {
    .rbc-month-view {
      filter: blur(3px) brightness(98%);
    }
  }
}

// calendar item styles
.rbc-event-content {
  padding: 4px;
  font-size: $font-size-small-text;
}

.rbc-toolbar-label {
  font-size: $font-size-small-headline;
}

// style the headers in the month view calendar (e.g. "MO", "DI", etc.)
.rbc-header {
  padding: 7px 7px 5px 7px;
  background-color: white;
  color: black;
  border-bottom: none;
}

// style the events in one day in the month view calendar
.rbc-row-segment {
  padding: 2px 11px 2px 2px;
  border: none;
}

// style the days from other months in the calendar
.rbc-off-range-bg {
  background-color: #f4f2f25d !important;
}

// style the days in the calendar
.rbc-day-bg {
  background-color: white;
}

// moth view calendar day styles
.rbc-button-link {
  margin-right: calc(100% - 55%);
  margin-top: 8px;
  margin-bottom: 4px;
}

// style the current day in the calendar
.rbc-today {
  @include themed() {
    background-color: t("color-primary");
    opacity: 0.2;
  }
}
