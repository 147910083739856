@import "styles/themes.scss";
@import "styles/utils.scss";

.widget-template-container {
  width: 100%;
  width: calc(50% - 20px);
  margin: 4px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid transparent;
  @include themed() {
    border: 1.5px solid t("color-primary");
  }

  @include for-phone-only {
    width: 100%;
  }

  &-active {
    @include themed() {
      background-color: rgba(t("color-primary"), 0.2);
    }
  }

  &-image {
    height: 60px;
    width: auto;
    object-fit: contain;
  }
}
