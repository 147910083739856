@import "styles/themes.scss";

.device-exercise-table-item {
  align-items: center;

  .exercise-device-list-index {
    font-weight: $font-weight-medium;

    @include themed() {
      color: t("color-primary");
    }
  }
}
