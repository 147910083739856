@import "styles/themes.scss";

.verification-code-preview {
  padding: 11px;
  border-radius: $main-border-radius;

  @include themed() {
    border: 1.5px solid t("color-border");
  }
}
