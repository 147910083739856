@import "styles/themes.scss";

.file-upload-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $main-border-radius;
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  box-sizing: border-box;

  @include themed() {
    border: dashed 2px t("color-border");
  }

  &--dragging {
    @include themed() {
      border: dashed 2px t("color-primary");
    }
  }

  &--error {
    @include themed() {
      border: dashed 2px t("color-error");
    }
  }

  .file-upload-placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-icon {
      font-size: 50px;

      @include themed() {
        color: t("color-border");
      }
    }

    &-text {
      @include themed() {
        color: t("color-border");
      }
    }
  }

  .file-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: calc($main-border-radius - 1px);
  }
}

.validation-message {
  height: 10px;
  @include themed() {
    color: t("color-error");
  }
}
