.loading-indicator-container {
  &--small {
    height: 30px;
    width: 30px;
  }

  &--medium {
    height: 80px;
    width: 80px;
  }

  &--large {
    height: 200px;
    width: 200px;
  }
}
