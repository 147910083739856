@import "styles/themes.scss";

.upsell-image {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.plan-item-wrapper {
  position: relative;

  .sales-container {
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    top: -20px;
    left: -20px;

    @include themed() {
      background-color: t("color-secondary");
    }

    &-text {
      color: white;
    }
  }

  .plan-item {
    padding: 40px;
    border-radius: 20px;

    @include themed() {
      border: 1.5px solid t("color-border");
    }
  }
}
