@import "styles/themes.scss";

.sum-container {
  @include themed() {
    border-radius: 10px;
    background-color: #f7fafa;
    padding: 20px;
  }

  .free-trial-sum {
    @include themed() {
      color: t("color-primary");
    }
  }
}
