@import "styles/themes.scss";

.app-configurator-preview-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-wrap-item {
  height: auto;
}
