@import "styles/themes.scss";

.template-image-container {
  position: relative;
  width: 100%;
  max-width: 180px;
  margin-right: 10px;
  margin-bottom: 10px;
  // aspect-ratio: 1/1;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;

  .template-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &--selected {
    @include themed() {
      border: 2px solid t("color-primary");
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include themed() {
        background-color: rgba(t("color-primary"), 0.5);
      }
    }
  }
}

.library-modal-wrapper {
  height: 100%;
  max-height: 80vh;
  min-height: 80vh;
}
