@import "styles/themes.scss";
@import "styles/utils.scss";

.sidebar-upsell-container {
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;

  @include for-tablet-only {
    display: none;
  }

  @include themed() {
    background-color: t("color-primary");
    box-shadow: t("shadow");
  }

  .upsell-icon {
    font-size: 30px;
    color: white;
  }

  .upsell-text {
    color: white;
  }
}

.sidebar-admin-info-container {
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;

  @include for-tablet-only {
    display: none;
  }

  @include themed() {
    background-color: t("color-warning");
    box-shadow: t("shadow");
  }
}
