.booking-nav-list-item-wrapper {
  width: 100%;
  border-radius: 5px;
  padding: 12px 15px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .nav-list-item-icon {
    position: absolute;
    right: 15px;
    top: 12px;
  }

  .nav-list-item-progress-bar {
    border-radius: 2px;
    position: absolute;
    left: 0px;
    bottom: 0;
    height: 2px;
    background-color: rgb(239, 134, 6);
  }

  .booking-is-selected {
    position: absolute;
    right: 0px;
    top: 0;
    height: 100%;
    width: 6px;
    background-color: rgba(255, 183, 0, 0.979);
  }
}
