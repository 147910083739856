@import "styles/themes.scss";

.text-editor-container {
  display: flex;
  flex-direction: column;
  border-radius: $main-border-radius;
  overflow: hidden;

  @include themed() {
    border: 1.5px solid t("color-surface");
  }

  &-toolbar {
    padding: 15px;

    @include themed() {
      background-color: t("color-surface-dark");
    }
  }

  &-content {
    padding: 15px;
  }
}

.ProseMirror {
  border: none;
  outline: none;
  overflow-y: auto;
  font-size: $font-size-running-text;
  font-weight: $font-weight-medium;
  min-height: 300px;

  > p {
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: $font-size-running-text;
    font-weight: $font-weight-medium;
    @include themed() {
      color: t("color-text");
    }
  }

  ul {
    margin: 0px;
    padding-left: 20px;
    line-height: 1;

    li {
      margin: 0px;
      padding: 0px;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  strong {
    font-weight: bold !important;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  &:active {
    border: none;
    outline: none;
  }

  &-focus {
    border: none;
    outline: none;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

iframe {
  border: none;
  width: 100%;
}
