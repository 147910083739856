@import "styles/themes.scss";
@import "styles/utils.scss";

.skeleton-detail-header-wrapper {
  padding: 0px $padding-page-content-left-right;
  height: 72px;

  @include themed() {
    background-color: t("color-background");
    border-bottom: 1.5px solid t("color-border");
  }

  @include for-phone-only {
    padding: 10px $padding-page-content-left-right-mobile 0
      $padding-page-content-left-right-mobile;
  }

  .skeleton-detail-header-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: flex-start;

    .skeleton-detail-seperator {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    .language-dropdown-skeleton {
      padding-top: 15px;
    }

    .tab-list-skeleton {
      padding-top: 20px;
    }
  }

  .skeleton-border {
    align-items: flex-start;
    margin-bottom: 20px;

    @include themed() {
      border-top: 1.5px solid t("color-border");
    }
  }
}
