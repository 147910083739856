@import "styles/themes.scss";

.form-item-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 125px;
  justify-content: space-between;

  @include themed() {
    border-bottom: 1.5px solid t("color-border");
  }

  .form-item-title {
    font-weight: $font-weight-medium;
  }

  .form-item-cotnainer {
    height: 100%;
    box-sizing: border-box;
    padding: 20px 25px 20px 0px;
    min-width: 50%;

    &-content {
      max-width: 400px;
    }
  }
}
